import { mapHelper } from "@/utils/utils.js";

// 	星期
const weekArr = [
  {
    value: 1,
    text: "星期一",
  },
  {
    value: 2,
    text: "星期二",
  },
  {
    value: 3,
    text: "星期三",
  },
  {
    value: 4,
    text: "星期四",
  },
  {
    value: 5,
    text: "星期五",
  },
  {
    value: 6,
    text: "星期六",
  },
  {
    value: 0,
    text: "星期日",
  },
];
const { map: weekArrMap, setOps: weekArrOps } = mapHelper.setMap(weekArr);
// 	预约状态
const appointStatusArr = [
  {
    value: 1,
    text: "预约成功",
  },
  {
    value: 2,
    text: "预约失败",
  },
  {
    value: 0,
    text: "预约中",
  },
];
const { map: appointStatusArrMap, setOps: appointStatusArrOps } =
  mapHelper.setMap(appointStatusArr);
// 	课程状态
const courseStatusArr = [
  {
    value: "",
    text: "预约",
  },
  {
    value: 2,
    text: "预约失败",
  },
  {
    value: 0,
    text: "预约中",
  },
];
const { map: courseStatusArrMap, setOps: courseStatusArrOps } =
  mapHelper.setMap(courseStatusArr);
export { weekArr, weekArrMap, appointStatusArrMap };
