<template>
  <div class="myEntrustChild">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
      :commonOperaParams="commonOperaParams"
    >
      <div class="item" v-for="(item, index) in listData" :key="index">
        <div class="oItem first">
          <div class="oItem-l"></div>
          <div :class="['oItem-r', 'te', returnClass(item.status)]">
            {{ appointStatusArrMap[item.status] }}
          </div>
        </div>
        <div class="oItem">
          <div class="oItem-l">家长姓名</div>
          <div class="oItem-r">{{ item.parentsName }}</div>
        </div>
        <div class="oItem">
          <div class="oItem-l">家长电话</div>
          <div class="oItem-r">{{ item.parentsPhone }}</div>
        </div>
        <div class="oItem">
          <div class="oItem-l">孩子姓名</div>
          <div class="oItem-r">{{ item.childName }}</div>
        </div>
        <div class="oItem">
          <div class="oItem-l">托幼时间</div>
          <div class="oItem-r te">
            {{ item.nurseryDay }} {{ item.nurseryTime }}
          </div>
        </div>
        <div class="oItem">
          <div class="oItem-l">课程内容</div>
          <div class="oItem-r te" @click="lookCourse(item)">查看课程</div>
        </div>
        <div class="oItem">
          <div class="oItem-l"></div>
          <div class="oItem-r btn" @click="toHandel(item)">审核</div>
        </div>
      </div>
    </v-list>
    <div class="courseDialog" v-if="isCourseDialog">
      <div class="courseDialog-c">
        <div class="courseDialog-t">
          <div class="course-t">
            {{ current.nurseryDay }} {{ current.nurseryTime }}
          </div>
          <div class="course-b">
            <div
              class="list"
              v-for="(item, index) in postData"
              :key="index"
              v-show="item.timeDuring == current.nurseryTime"
            >
              <div class="list-l">
                <div class="list-l-c">
                  <p>{{ item.activityName }}</p>
                  <h3>{{ item.activityTime }}</h3>
                </div>
              </div>
              <div class="list-r">{{ item.activityDetail }}</div>
            </div>
          </div>
        </div>
        <div class="courseDialog-b" @click="close">
          <img src="./img/close.png" alt="" />
        </div>
      </div>
    </div>

    <v-dialog v-model="isDialog" title="请选择" @confirm="confirm">
      <v-radio-group
        @change="change"
        :radioObj="radioObj"
        v-model="form.applyStatus"
      ></v-radio-group>
    </v-dialog>
  </div>
</template>

<script>
import {
  selectMyApplyUrl,
  handelStatusUrl,
  selectCourseUrl,
  selectAllApplyUrl,
} from "./api.js";
import { appointStatusArrMap } from "./map.js";
var moment = require("moment");
export default {
  name: "myEntrustChild",
  data() {
    return {
      current: {},
      postData: [],
      amList: [],
      pmList: [],
      appointStatusArrMap,
      radioObj: [
        { name: "1", value: "通过" },
        { name: "2", value: "拒绝" },
      ],
      isDialog: false,
      finishedText: "没有更多了",
      finished: false,
      isCourseDialog: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      listData: [1],
      form: {
        applyStatus: "1",
      },
      id: "",
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  mounted() {},
  methods: {
    close() {
      this.isCourseDialog = false;
    },
    lookCourse(item) {
      this.current = item;
      this.isCourseDialog = true;
      this.postData = [];
      if (item.nurseryDay) {
        this.getPartyList(item.nurseryDay);
      }
    },
    async getPartyList(dayData) {
      let params = {
        userId: this.userId,
        dayData: dayData,
      };
      let res = await this.$axios.post(
        selectCourseUrl,
        this.$qs.stringify(params)
      );
      if (res.code === 200) {
        this.postData = res.data;
        this.postData.forEach((ele) => {
          if (ele.timeDuring == "上午") {
            this.amList.push(ele);
          }
          if (ele.timeDuring == "下午") {
            this.pmList.push(ele);
          }
        });
      } else {
      }
    },
    returnClass(data) {
      if (data == 0) {
        return "yellow";
      } else if (data == 1) {
        return "blue";
      } else if (data == 2) {
        return "grey";
      }
    },
    change(value) {
      console.log(value);
      this.form.applyStatus = value;
    },
    async handelStatusUrl() {
      let _this = this;
      let params = {
        applyId: _this.id,
        applyStatus: _this.form.applyStatus,
      };
      let res = await this.$axios.post(
        `${handelStatusUrl}`,
        this.$qs.stringify(params)
      );
      if (res.code === 200) {
        this.isDialog = false;
        this.requestData.curPage = 1;
        this.getCreateWork();
      }
    },
    confirm() {
      this.handelStatusUrl();
    },
    toHandel(item) {
      this.isDialog = true;
      this.id = item.id;
    },
    returnTime(time) {
      if (time) {
        return moment(time).format("HH:mm");
      }
    },
    onLoad() {
      this.getCreateWork();
    },
    getCreateWork() {
      this.listData = [];
      let params = {
        userId: this.userId,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
      };
      this.$axios
        .post(`${selectAllApplyUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.myEntrustChild {
  background: #fefefe;
  min-height: 100vh;
  padding: 46px 32px;
  box-sizing: border-box;
  .item {
    height: 682px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 16px;
    padding: 0 14px;
    margin-bottom: 46px;
    .oItem {
      padding: 26px 14px 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .oItem-l {
        font-size: 32px;
        font-weight: 700;
        color: #333333;
        line-height: 44px;
      }
      .oItem-r {
        font-size: 28px;
        font-weight: 700;
        color: #333333;
        line-height: 40px;
      }
      .te {
        font-size: 28px;
        font-weight: 700;
        color: #007eff;
        line-height: 40px;
      }
      &:nth-last-child(1) {
        border: none;
      }
      .yellow {
        color: #ffb24b;
      }
      .grey {
        color: #a8a8a8;
      }
      .btn {
        width: 138px;
        height: 52px;
        background: #007eff;
        border-radius: 26px;
        font-size: 28px;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 52px;
      }
    }
    .first {
      padding: 16px 14px 18px;
      .te {
        font-weight: 400;
      }
    }
  }
  .courseDialog {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
  }
  .courseDialog-c {
    position: absolute;
    width: 612px;
    box-sizing: border-box;
    max-height: 624px;
    background: #ffffff;
    box-shadow: 0px 0px 42px 0px rgba(66, 114, 153, 0.1);
    border-radius: 21px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    .course-t {
      padding: 26px;
      font-size: 36px;
      font-weight: 600;
      color: #007eff;
      line-height: 50px;
    }
    .course-b {
      overflow: auto;
      max-height: 500px;
    }
    .list {
      display: flex;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
      .list-l {
        flex: 1;
        flex-shrink: 0;
        padding: 14px 16px;
        box-sizing: border-box;
      }
      .list-r {
        flex: 1;
        flex-shrink: 0;
        padding: 14px 16px;
        box-sizing: border-box;
      }
      .list-l {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 400;
        color: #151c39;
        line-height: 34px;
        border-right: 1px solid rgba(0, 0, 0, 0.06);
      }
      .list-r {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 400;
        color: #151c39;
        line-height: 34px;
      }
    }
    .courseDialog-b {
      width: 80px;
      height: 80px;

      position: absolute;
      bottom: -100px;
      margin: auto;
      left: 0;
      right: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .v-dialog {
    /deep/ .van-dialog__content {
      padding: 10px 0;
      .van-radio-group {
        justify-content: center;
      }
    }
  }
}
</style>
<style lang="less">
.workArea {
}
</style>
